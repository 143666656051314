import React, { useEffect, useState } from "react"
import Rellax from 'rellax'
import './styles.scss'
import Img from 'gatsby-image'
import closeIcon from '../../../images/jacob-freeman/close.svg'

const ImageSection = ({content}) => {
  const [openModal, setOpenModal] = useState(false)

  // useEffect(() => {
  //   // Init Rellax animations
  //   new Rellax(".rellax", { // <---- Via class name
  //     speed: -1,
  //     center: false,
  //     wrapper: null,
  //     round: true,
  //     vertical: true,
  //     horizontal: false
  //   });
  // }, [])

  const handleOpenModal = () => {
    document.body.style.overflow = 'hidden'
    setOpenModal(true)
  }

  const closeModal = () => {
    document.body.style.overflow = 'auto'
    setOpenModal(false)
  }

  return (
    <section data-rellax-speed="4" className={`image-section rellax ${openModal ? 'image-section--reset' : ''}`}>
      <div style={{display: openModal ? 'block' : 'none'}} className="image-section__detail">
        <img
          className="image-section__detail__close"
          src={closeIcon}
          onClick={closeModal}
        />
        <div className="image-section__detail__text">
          <div className="image-section__detail__text__artist">Jacob Freeman</div>
          <div className="image-section__detail__text__name">{content.name}</div>
          <div className="image-section__detail__text__wrapper">
            <div className="image-section__detail__text__date">2022</div>
            <div className="image-section__detail__text__material">{content.material}</div>
            <div className="image-section__detail__text__size">{content.size}</div>
          </div>
          <div className="image-section__detail__text__desc">{content.desc}</div>
        </div>

        <Img
          // style={{visibility: visible ? 'visible' : 'hidden'}}
          className="image-section__image"
          fluid={content.detail_source.childImageSharp.fluid}
          // placeholderStyle={{ visibility: "hidden" }}
        />

        <div className="image-section__detail__b-image">
          <img src={content.bottom_source.publicURL} />
          {/* <Img
            // style={{visibility: visible ? 'visible' : 'hidden'}}
            className="image-section__image"
            fluid={content.bottom_source.childImageSharp.fluid}
            // placeholderStyle={{ visibility: "hidden" }}
          /> */}
        </div>
      </div>
      <div onClick={handleOpenModal}>
        <Img
          style={{cursor: 'pointer'}}
          // style={{visibility: visible ? 'visible' : 'hidden'}}
          className="image-section__image"
          fluid={content.source.childImageSharp.fluid}
          // placeholderStyle={{ visibility: "hidden" }}
        />
      </div>
      <div className="image-section__text-wrapper">
        <div className="image-section__title">{content.title}</div>
        <div className="image-section__name">{`${content.name}`}<span>, 2022</span></div>
        <div className="image-section__material">{content.material}</div>
        <div className="image-section__size">{content.size}</div>
        <div className="image-section__inquery"><a href="mailto:hello@rainraingallery.com" target='_blank'>Inquire</a></div>
      </div>
    </section>
  )
}

export default ImageSection
