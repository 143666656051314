import React from "react"
import './styles.scss'
import Img from 'gatsby-image'

const TextSection = ({content}) => {
  const pdf_link = 'https://assets.ctfassets.net/yon2u6qy3zpn/3t8xPPuMohjBE2FBYZQRek/4bda3dc00adcb17b713097b03ccfab5c/RR_JF_Preview.pdf'

  return (
    <div className="text-section">
      {
        content.quote ?
        <div className="text-section__quote">“</div> :
        null
      }
      <div className={`text-section__title ${content.quote ? 'text-section__title--quote' : ''}`} dangerouslySetInnerHTML={{ __html: content.title }} />
      {/* <div className="text-section__title">{content.title}</div> */}
      {/* <div className="text-section__text">{content.text}</div> */}
      <div className={`text-section__text ${content.class === 'full' ? 'text-section__text--full' : ''}`} dangerouslySetInnerHTML={{ __html: content.text }} />
      {
        content.pdfLink ?
        <div className="text-section__pdf">
          <a href={pdf_link} target='_blank'>View Works in PDF</a>
        </div> :
        null
      }
    </div>
  )
}

export default TextSection
