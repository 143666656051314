import React, { useState, useEffect, useRef } from "react"
import { Link, graphql } from "gatsby"
import MailchimpSubscribe from "react-mailchimp-subscribe"

import './styles.scss'

import HeaderSection from '../../../components/jacob-freeman/header-section/HeaderSection'
import TextSection from '../../../components/jacob-freeman/text-section/TextSection'
import ImageSection from '../../../components/jacob-freeman/image-section/ImageSection'
import ProfileSection from '../../../components/jacob-freeman/profile-section/ProfileSection'

// const url = "https://rainraingallery.us14.list-manage.com/subscribe/post?u=1511c581d31380e2cb0fc607d&id=771f9dc947"


const JacobPage = ({data}) => {
  const content = data.allJacobFreemanYaml.edges

  const [isEmailEntered, setIsEmailEntered] = useState(false)
  // const [skipMask, setSkipMask] = useState(false)

  const removeLocal = () => {
    if(typeof window !== "undefined") {
      localStorage.setItem("email", '')
    }
  }

  useEffect(() => {
    if(typeof window !== "undefined") {
      const query = new URLSearchParams(window.location.search);

      if(localStorage.getItem('email')=="entered" || query.get('pastspeakeasy')) {
        setIsEmailEntered(true)
      }
    }
  }, [])

  const handleBackToTop = () => {
    if(typeof window !== "undefined") {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
	}

  const handleEnterEmail = () => {
    setIsEmailEntered(true)
  }

  return (
    <div className={`jacob-freeman ${isEmailEntered ? 'visible' : 'invisible'}`}>
\      <Link to="/speakeasy/" className="jacob-freeman__exit">EXIT</Link>
      {/* <div className='mask' style={{display: isEmailEntered || skipMask ? 'none' : 'flex'}}>
        <div className="inner">
          <img className="icon" src={rain_icon}/>
          <div className="first">Let us know you're here:</div>
          <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => (
              <Form
                status={status}
                message={message}
                onValidated={formData => subscribe(formData)}
                enterEmail={handleEnterEmail}
              />
            )}
          />
          <div className="skip">
            <div className="text" onClick={() => setSkipMask(true)}>Skip, and take me to the show</div>
          </div>
          <div className="second">We will process the personal data you have supplied accrodance with our privacy policy. You can unsbscribe or change your preferences at any time by clicking the link in any emails.</div>
        </div>
      </div> */}
      {
        content.map(node => {
          switch(node.node.type) {
            case 'header':
              return <HeaderSection content={node.node} />
              break;
            case 'text':
              return <TextSection content={node.node} />
              break;
            case 'image':
              return <ImageSection content={node.node} />
              break;
            case 'profile':
              return <ProfileSection content={node.node} />
              break;
            default:
              // code block
          }
        })
      }
      <div className="jacob-freeman__back" onClick={handleBackToTop}>
        <div className="text">Back to Top</div>
      </div>
    </div>
  )
}

// const Form = ({ status, message, onValidated, enterEmail }) => {
//   const input = useRef(null)
//   const [inputValue, setInputValue] = useState('')
//   const [onError, setOnerror] = useState(false)

//   function ValidateEmail(mail) {
//     if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
//       {
//         return (true)
//       }
//         return (false)
//     }

//   let email;

//   const handleSubmit = () => {
//     if(ValidateEmail(inputValue)) {
//       enterEmail()
//       if(typeof window !== "undefined") {
//         window.localStorage.setItem('email', 'entered')
//       }

//       submit()
//     } else {
//       setOnerror(true)
//     }
//   }

//   const submit = () =>
//     inputValue.indexOf("@") > -1 &&
//     onValidated({
//       EMAIL: inputValue
//     });

//   return (
//     <div style={{position: 'relative'}}>
//       {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
//       {status === "error" && (
//         <div
//           style={{ color: "red" }}
//           dangerouslySetInnerHTML={{ __html: message }}
//         />
//       )}
//       {status === "success" && (
//         <div
//           style={{ color: "green" }}
//           dangerouslySetInnerHTML={{ __html: message }}
//         />
//       )}
//       <input
//         style={{width: '100%', borderBottom: '2px white solid', paddingBottom: '4px', border: 'none', borderBottom: '1px black solid', outline: 'none'}}
//         ref={input}
//         ref={node => (email = node)}
//         type="email"
//         // onFocus={() => {setInputOnFocus(true)}}
//         // onBlur={handleOnBlur}
//         onChange={e => {setInputValue(e.target.value)}}
//         placeholder='Email Address'
//       />

//       <div className="errorMsg" style={{visibility: onError ? 'visible' : 'hidden'}}>Please enter valid email</div>

//       <div
//           className="button"
//           onClick={handleSubmit}
//           style={{color: 'white', position: 'absolute', right: '0', bottom: '4px', paddingRight: '0'}}
//         >Enter the Show</div>
//     </div>
//   );
// };

export const query = graphql`
  query {
    allJacobFreemanYaml {
      edges {
        node {
          date
          subtitle
          description
          text
          title
          type
          class
          name
          material
          size
          artistname
          pdfLink
          desc
          quote
          source {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          detail_source {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          bottom_source {
            publicURL
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          profileimage {
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

export default JacobPage
