import React, { useState, useEffect, useRef } from "react"
import './styles.scss'
import Img from 'gatsby-image'
import playIcon from '../../../images/jacob-freeman/play.svg'
import closeIcon from '../../../images/jacob-freeman/close--white.svg'
import poster from '../../../images/jacob-freeman/poster.jpg'

const clippedVideoLink = "https://videos.ctfassets.net/yon2u6qy3zpn/3BNWwUl2tmWdxqmBptZyCJ/8edaaf52642a17e3d0d0d148ff0d6b92/jacob-freeman-clipped.mp4";
const videoLink = "https://videos.ctfassets.net/yon2u6qy3zpn/37gstqnHNNwvOufJDJWomu/e19bf576134465cef147484788da33ac/jacob-freeman_final.mp4";

const HeaderSection = ({content}) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const videoEl = useRef(null)

  useEffect(() => {
    videoEl.current.currentTime = 0;
    isPlaying ? videoEl.current.play() : videoEl.current.pause()
    isPlaying ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'auto'
  }, [isPlaying])

  const handleClick = () => {
    videoEl.current.muted = false
    setIsPlaying(true)
    // openFullscreen();
  }

  // const openFullscreen = () => {
  //   const elem = videoEl.current
  
  //   if (elem.requestFullscreen) {
  //     elem.requestFullscreen();
  //   } else if (elem.webkitRequestFullscreen) { /* Safari */
  //     elem.webkitRequestFullscreen();
  //   } else if (elem.msRequestFullscreen) { /* IE11 */
  //     elem.msRequestFullscreen();
  //   }
  // }

  return (
    <div className="header-section">
      <div className={`header-section__video-wrapper ${isPlaying ? 'is-visible' : ''}`}>
      <div onClick={() => {setIsPlaying(false)}}>
        <img className="header-section__video-wrapper__close" src={closeIcon} />
      </div>
      <video
        className="header-section__video"
        ref={videoEl}
        preload='auto'
        muted
        controls
      >
        <source src={videoLink} type="video/mp4" />
        Your browser does not support HTML5 video.
      </video>
      </div>
      <div className="header-section__wrapper">
        <div className="header-section__wrapper__left">
          <h1 className="title">{content.title}</h1>
          <div className="subtitle">{content.subtitle}</div>
          <div className="date">{content.date}</div>
        </div>
        <div className="header-section__wrapper__right">
          <img className="header-section__wrapper__right__icon" src={playIcon} />
          <video
            preload='auto'
            loop autoPlay muted
            onClick={handleClick}
          >
            <source src={clippedVideoLink} type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
          <img onClick={handleClick} className="header-section__wrapper__right__mobile-poster" src={poster} />
        </div>
      </div>
      <div className="header-section__desc">{content.description}</div>
    </div>
  )
}

export default HeaderSection
