import React from "react"
import './styles.scss'
import Img from 'gatsby-image'

const ProfileSection = ({content}) => {
  return (
    <div className="profile-section">
      <div className="profile-section__left">
        <div className="profile-section__title">{content.title}</div>
        <div className='profile-section__text' dangerouslySetInnerHTML={{ __html: content.text }} />
      </div>
      <div className="profile-section__right">
        <Img
          // style={{visibility: visible ? 'visible' : 'hidden'}}
          className="profile-section__right__image"
          fluid={content.profileimage.childImageSharp.fluid}
          // placeholderStyle={{ visibility: "hidden" }}
        />
        <div className="profile-section__right__name">{content.artistname}</div>
      </div>
    </div>
  )
}

export default ProfileSection
